import React from 'react'
import { EventEmitter } from 'events'
import { canUseDOM } from '../hoc'

export const globalBus = new EventEmitter()
if (canUseDOM) {
  ;(window as any).globalBus = globalBus
}

export const BusContext = React.createContext<EventEmitter>(globalBus)
const P = BusContext.Provider

export function useBus() {
  return React.useContext(BusContext)
}

export function useListener(name: string | symbol | undefined, fn: (...args: any[]) => void) {
  const bus = useBus()
  React.useEffect(() => {
    if (name) bus.on(name, fn)
    return () => {
      if (name) bus.off(name, fn)
    }
  }, [bus, name, fn])
}

export const BusProvider: React.FC<{ bus: EventEmitter }> = ({ children, bus }) => {
  return <P value={bus}>{children}</P>
}
