import { Branch, CanManageProduct, ClientDetail, ClientProduct } from '@odiupsk/up-api-client'
import { computed, observable, runInAction } from 'mobx'
import React from 'react'
import router from 'next/router'
import { PRODUCT, PRODUCTS } from '../config/constants'

import { ApiClientsRepository } from '../common/api/ApiClientsRepository'
import { appStore } from '../common/AppStore'
import { globalBus } from '../common/utils/hooks/useGlobalBus'

const CLIENT_ID_LOCAL_STORAGE_KEY = 'context.clientId'

export const RELOAD_CLIENT = Symbol('RELOAD_CLIENT')

export class ClientStore {
  @observable client?: ClientDetail = undefined

  @observable branch?: Branch = undefined

  @observable activeProduct: PRODUCT | undefined = undefined

  @observable availableProducts?: ClientProduct[] = undefined

  @observable hasStock?: boolean = false

  @observable isLoading = false

  constructor() {
    globalBus.on(RELOAD_CLIENT, () => {
      if (!this.client) return console.warn('`client` not defined')
      this.fetchClient(this.client.id)
    })
  }

  async fetchClient(id: string) {
    this.isLoading = true

    const res = await Promise.all([
      ApiClientsRepository.fetchClient(id),
      ApiClientsRepository.fetchBranch(id),
      ApiClientsRepository.fetchProducts(id),
    ])

    this.saveClientIdToLocalStorage(id)

    const availableProducts = res[2]?.data || []

    runInAction(() => {
      this.client = res[0]
      this.branch = res[1]
      this.availableProducts = availableProducts
      this.activeProduct = this.getDefaultProduct()
      this.isLoading = false
    })

    if (process.browser) {
      // CHECK IF CLIENT HAS ACCESS TO CURRENT URL
      const forbiddenUrls = this.getForbiddenUrls()
      const currentUrl = router.pathname

      for (const forbiddenUrl of forbiddenUrls) {
        if (currentUrl.startsWith(forbiddenUrl)) {
          return
        }
      }

      // SET ACTIVE PRODUCT IF ON PRODUCT URL
      const productFromUrl = this.getProductFromUrl()
      if (productFromUrl) {
        this.activeProduct = productFromUrl
      }
    }
  }

  reload() {
    this.fetchClient(this.clientId)
  }

  @computed get clientId() {
    if (!this.client) throw new Error(`Client not fetched`)
    return this.client && this.client.id
  }

  @computed get branchId() {
    if (!this.branch) throw new Error(`Branch not fetched`)
    return this.branch && this.branch.id
  }

  saveClientIdToLocalStorage = (employeeId: string) => {
    localStorage.setItem(CLIENT_ID_LOCAL_STORAGE_KEY, employeeId)
  }

  getClientIdFromLocalStorage = () => {
    return localStorage.getItem(CLIENT_ID_LOCAL_STORAGE_KEY)
  }

  getProductFromUrl = () => {
    const availableProductCodes = this.getManageableProductCodes()
    const availableProducts = Object.values(PRODUCTS).filter(p =>
      availableProductCodes.includes(p.code)
    )
    if (process.browser && availableProducts?.length) {
      const currentUrl = router.pathname
      for (const p of availableProducts) {
        if (p.pages.find(page => page.href === currentUrl)) {
          return p.code
        }
      }
    }
    return undefined
  }

  getDefaultProduct = () => {
    // RETURN ACTIVE PRODUCT
    if (this.activeProduct) {
      return this.activeProduct
    }

    // CHECK IF PRODUCT IS SPECIFIED IN URL PARAM
    if (process.browser && router.pathname === '/client') {
      const productIdFromUrl = new URLSearchParams(location.search)?.get('product')
      if (productIdFromUrl) {
        const productId = parseInt(productIdFromUrl, 10)
        const isManageable = !!this.getManageableProducts().find(p => p.product_id === productId)
        const product = isManageable && this.getProductDefById(productId)
        if (product) {
          return product.code
        }
      }
    }

    // RETURN DEFAULT PRODUCT
    const manageableProductCodes = this.getManageableProductCodes()
    if (manageableProductCodes?.length) {
      return manageableProductCodes[0]
    }
    return undefined
  }

  getProductDefById = (productId: number) => {
    if (productId !== undefined) {
      return Object.values(PRODUCTS).find(obj => obj?.id === productId)
    }
    return undefined
  }

  getAvailableProductCodes: () => PRODUCT[] = () => {
    if (this.availableProducts?.length) {
      const availableProductCodes = this.availableProducts
        ?.map(p => Object.values(PRODUCTS).find(product => product.id === p.product?.id)?.code)
        .filter(p => p)

      return availableProductCodes as PRODUCT[]
    }

    return []
  }

  getAvailableProductIds: () => (number | undefined)[] = () => {
    if (this.availableProducts?.length) {
      return this.availableProducts?.map(p => p.product?.id).filter(id => !isNaN(id as number))
    }
    return []
  }

  getManageableProducts: () => CanManageProduct[] = () => {
    const can_manage_products =
      appStore.currentUserAccesses?.find(a => a.scope_object_id === this.client?.id)
        ?.can_manage_products || []
    const manageableProductIds = can_manage_products.map(p => p.product_id)

    // sort by fetched products
    const sorted: CanManageProduct[] = []
    this.availableProducts?.forEach(v => {
      const productId = v.product?.id
      if (productId !== undefined && manageableProductIds.includes(productId)) {
        const product = can_manage_products.find(p => p.product_id === productId)
        if (product) {
          sorted.push(product)
        }
      }
    })

    return sorted
  }

  getManageableProductCodes: () => PRODUCT[] = () => {
    const manageableProducts = this.getManageableProducts()
    if (manageableProducts?.length) {
      const manageableProductCodes = manageableProducts
        ?.map(p => Object.values(PRODUCTS).find(product => product.id === p.product_id)?.code)
        .filter(p => p)
      return manageableProductCodes as PRODUCT[]
    }
    return []
  }

  getProductAdminRights = (productId: number) => {
    return this.getManageableProducts()?.find(p => p.product_id === productId)
  }

  getForbiddenUrls = () => {
    const availableProductCodes = clientStore.getManageableProductCodes()
    const forbiddenUrls: string[] = []
    Object.values(PRODUCTS).forEach(p => {
      if (!availableProductCodes.includes(p.code)) {
        forbiddenUrls.push(...(p.pages || []).map(page => page.href))
      }
    })
    return forbiddenUrls
  }
}

export const clientStore = new ClientStore()

/**
 * Use only after client was fetched & set on EmployeeCtx.Provider !!!
 */
export const ClientCtx = React.createContext<ClientDetail>(clientStore.client!)
